<template>
  <div class="tab-pane active">
    <div class="responsive-table">
      <div class="loading min-h-300" v-if="loading">
        <LoadingAnim />
      </div>
      <table v-else class="table table-borderless">
        <thead class="">
          <tr>
            <th scope="col">{{ $tc('app.name', 1) }}</th>
            <!-- Data -->
            <th scope="col">{{ $t('app.created_at') }}</th>
            <!-- Tipo -->
            <th scope="col">{{ $t('app.sent_at') }}</th>
            <!-- Remetente -->
            <th scope="col">Status</th>
            <!-- Destinatario -->
            <th scope="col">{{ $tc('app.message', 2) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="campaign in campaigns"
            :key="campaign.id"
            :to="`history/${campaign.id}`"
          >
            <td data-label="Data">
              {{ campaign.name }}
            </td>
            <td data-label="Tipo">
              {{ campaign.created_at | date('DD/MM/YYYY HH:mm:ss') }}
            </td>
            <td data-label="Remetente">
              {{ campaign.scheduled_at | date('DD/MM/YYYY HH:mm:ss') }}
            </td>
            <td data-label="Status">
              <!-- Pendente -->
              <span
                v-if="campaign.status == 'queued'"
                class="badge badge-light"
                >{{ $t('generic-str.scheduled') }}</span
              >
              <!-- Em Fila -->
              <span
                v-else-if="campaign.status == 'in_progress'"
                class="badge badge-info"
                >{{ $t('generic-str.sending') }}</span
              >
              <!-- Enviando -->
              <span
                v-else-if="campaign.status == 'paused'"
                class="badge badge-warning"
                >{{ $t('generic-str.paused-a') }}</span
              >
              <!-- Enviado -->
              <span
                v-else-if="campaign.status == 'sent'"
                class="badge badge-success"
                >{{ $tc('generic-str.sent-a') }}</span
              >
              <!-- Entregue -->
              <span
                v-else-if="campaign.status == 'stopped'"
                class="badge badge-danger"
                >{{ $t('generic-str.no-funds') }}</span
              >
            </td>
            <td data-label="Destinatário">{{ campaign.total_messages }}</td>
            <td>
              <span
                class="col"
                v-tooltip.top="
                  `<b>Interface:</b> ${campaign.interface}</br>
                            <b>` +
                  $t('generic-str.content') +
                  `:</b> ${campaign.body}</br>` /*Conteúdo*/
                "
              >
                <i class="icon dripicons-information"></i>
              </span>
              <a
                v-if="campaign.status != 'queued'"
                role="button"
                @click="$emit('filter', 'history', campaign.id)"
                class="col"
                v-tooltip.top="$tc('generic-str.message', 2)"
                ><!-- Mensagens -->
                <i class="icon dripicons-mail"></i>
              </a>
              <a
                v-if="campaign.status != 'queued'"
                role="button"
                @click="$emit('filter', 'stat', campaign.id)"
                class="col"
                v-tooltip.top="$tc('generic-str.report', 2)"
              >
                <!-- Relatórios -->
                <i class="icon dripicons-graph-pie"></i>
              </a>
              <a
                v-if="campaign.status == 'in_progress'"
                @click="pause(campaign.id)"
                href="javascript:void(0)"
                class="col"
                v-tooltip.top="$t('generic-str.pause')"
              >
                <!-- Pausar -->
                <i class="icon dripicons-media-pause"></i>
              </a>
              <a
                v-if="
                  campaign.status == 'stopped' || campaign.status == 'paused'
                "
                @click="play(campaign.id)"
                href="javascript:void(0)"
                class="col"
                v-tooltip.top="$t('generic-str.send')"
              >
                <!-- Enviar -->
                <i class="icon dripicons-media-play"></i>
              </a>
              <a
                href="javascript:void(0)"
                class="col"
                v-if="campaign.status === 'queued'"
                v-modal="{
                  target: 'edit-campaign-modal',
                  data: campaign,
                }"
                v-tooltip.top="$t('generic-str.edit')"
              >
                <!-- Editar -->
                <i class="icon dripicons-pencil"></i>
              </a>
              <a
                href="javascript:void(0)"
                class="col"
                v-if="campaign.status === 'queued'"
                v-tooltip.top="$t('generic-str.remove')"
                @click="remove(campaign.id)"
              >
                <!-- Remover -->
                <i class="icon dripicons-cross"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <pagination :lastPage="pages" @change="fetch" />
      </div>
    </div>
    <edit-campaign-modal id="edit-campaign-modal" @submit="fetch(1)" />
  </div>
</template>

<script>
// @ is an alias to /src
import Swal from 'sweetalert2';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import SmsService from '@/services/sms.service';
import Pagination from '@/components/Pagination.vue';
import EditCampaignModal from '@/components/sms/campaigns/EditCampaignModal.vue';

export default {
  name: 'PipelineHistoryTab',
  components: {
    // PageHeader,
    Pagination,
    LoadingAnim,
    EditCampaignModal,
  },
  props: {
    filters: {
      type: Object,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      fetched: true,
      campaigns: [],
      form: {
        id: '',
        status: '',
        from: '',
        to: '',
        scheduled_at: {
          gte: '',
          lte: '',
        },
        order_by: ['scheduled_at', 'desc'],
        page: 1,
      },
      pages: 1,
      exporting: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = 1) {
      this.loading = true;

      this.$store
        .dispatch('fetchSmsCampaigns', {
          filter: {
            ...this.filters,
            scheduled_at: {
              gte: this.startDate,
              lt: this.endDate,
            },
          },
          page,
        })
        .then((response) => {
          this.campaigns = response.data;
          this.pages = response.last_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    pause(id) {
      SmsService.editCampaign({ status: 'paused', id: `${id}` }).then(
        () => {
          this.fetch(this.form.page);
        },
        (error) => {
          this.content = error;
          console.log(error);
        },
      );
    },
    play(id) {
      SmsService.editCampaign({ status: 'in_progress', id: `${id}` }, id).then(
        () => {
          this.fetch(this.form.page);
        },
        (error) => {
          this.content = error;
          console.log(error);
        },
      );
    },
    exportData(format) {
      this.$store.dispatch('exportSmsMessages', {
        filter: this.filters,
        export: format,
      });
    },
    exportMessages() {
      this.exporting = true;
      SmsService.exportSms({
        filter: {
          id: this.form.id,
          from: this.form.from,
          to: this.form.to,
          status: this.form.status,
          sent_at: {
            from: this.beginDate,
            to: this.endDate,
          },
        },
      })
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.toast.report'),
              type: 'success',
            });
            this.$router.push('/sms/exports');
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.exporting = false;
        });
    },
    remove(campaignId) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('warnings.campaign.delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          SmsService.deleteCampaign(campaignId).then(
            () => {
              this.fetch(this.form.page);
              Swal.fire(
                this.$t('generic-str.deleted'),
                this.$t('warnings.campaign.deleted'),
                'success',
              );
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}

/* Rebranding */
.rebranding .card.borderless {
  box-shadow: unset !important;
}

.form-group {
  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}
.icon-success {
  color: #6eda2c;
}
.icon-failed {
  color: #ff0000;
}
</style>
